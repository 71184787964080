.khp-primary-topview-hotspot {
  width: 30px;
  height: 30px;
  background-color: rgba(227, 207, 143, 0.5);
  // background-color: white;
  // box-shadow: 1px 1px 1px black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #e3cf8f;
    border-radius: 50%;
  }

  &::after {
    display: block;
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    // border: 1px solid #d6b623;
    background-color: transparent;
    animation: topviewPulsePoint 1s infinite;
  }

  &__title {
    position: absolute;
    width: 120px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #ffc000;
    background-color: #105260;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 100px));

    .title-01,
    .title-02 {
      color: #ffc000;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .title-01 {
      font-size: 10px;
      font-weight: 300;
    }

    .title-02 {
      font-size: 12px;
      font-weight: 600;
    }

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 70px;
      top: 100%;
      border-left: 2px dashed #ffc000;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
      position: absolute;
      left: 50%;
      transform: translateX(-1px);
    }
  }
}

@keyframes topviewPulsePoint {
  0% {
    box-shadow: 0 0 0 0 rgb(227, 207, 143);
  }

  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
