.apartment-popup {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;

  &--container {
    position: relative;
    height: 180px;
    width: 280px;
    border-radius: 10px;
    background-color: #2b718e;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--cancle {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 20px;
    height: 20px;
    display: flex;
    // align-items: center;
    justify-content: center;
    color: black;
    background-color: white;
    border-radius: 50%;
    outline: none;
    border: 1px transparent solid;
    line-height: 16px;
    cursor: pointer;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }

  &--btns {
    text-align: center;
  }

  &--btn {
    margin: 10px;
    font-size: 16px;
    width: 235px;
    // text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    font-weight: 600;
    color: white;
    background-color: transparent;
    outline: none;
    border: none;
    padding: 15px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0.85;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}
