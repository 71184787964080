.level-switch-wp {
  position: fixed;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: auto;
  display: none;
  transition: 0.3s ease;

  &.switch-enable {
    display: flex;
  }

  .level-switch {
    width: 169px;
    height: 32px;
    padding: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #1b5b76;
    border-radius: 15px;
    border: 1px solid white;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);

    .level-switch-text,
    .level-switch-item {
      height: 26px;
      line-height: 26px;
    }

    .level-switch-text {
      padding: 0 6px 0 10px;
      color: #ffc000;
      font-weight: 800;
    }

    .level-switch-item {
      font-weight: 600;
      color: #105260;
      width: 26px;
      border-radius: 15px;
      border: 1px solid rgba(0, 0, 0, 0.5);
      background-color: white;
      text-align: center;
      margin-left: 3px;
      pointer-events: all;
      cursor: pointer;

      &.active {
        background-color: #ffc000;
      }
    }

    .apartment-switch-item {
      width: 88px;
    }
  }

  .apartment-switch {
    width: 247px;
  }
}

@media screen and (max-width: 767px) {
  .level-switch-wp {
    bottom: 6.5px;
    right: 12px;
    top: unset;
    transform: unset;
    z-index: 2;
    justify-content: flex-end;
    align-items: center;

    .level-switch {
      height: 36px;
      justify-content: space-between;
      border-radius: 18px;
      padding-top: 3px;
      padding-bottom: 3px;

      .level-switch-text,
      .level-switch-item {
        height: 30px;
        line-height: 30px;
        margin: 0;
      }

      .level-switch-item {
        width: 30px;
      }

      .apartment-switch-item {
        height: 31px;
        width: 31px;
      }
    }

    .apartment-switch {
      width: 130px;
    }
  }
}

@media screen and (min-width: 1500px) {
  .level-switch-wp {
    .level-switch {
      width: 204px;
      height: 40px;
      border-radius: 20px;

      .level-switch-text,
      .level-switch-item {
        height: 32px;
        line-height: 32px;
        font-size: 14px;
      }

      .level-switch-item {
        width: 32px;
      }

      .apartment-switch-item {
        width: 130px;
      }
    }

    .apartment-switch {
      width: 350px;
    }
  }
}
