.heading-indicator {
  opacity: 0;

  transition: 2s ease;

  &.show {
    opacity: 1;
    transition: 0s ease;
  }
}

.circular-sector {
  opacity: 0;
  transition: 1s ease;

  &.show {
    opacity: 1;
  }
}
